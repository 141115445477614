.App {
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #666666;
  margin: 0;
  line-height: 1.4;
}

.Link {
  /* color: #704AFF; */
}

a,
a:visited {
  color: #000 !important;
  text-decoration: none !important;
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
}

a:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;
}

.App-logo {
  width: 100%;
  height: auto;
  max-width: 500px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

.App-link {
  /* color: #61dafb; */
}
